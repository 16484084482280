import Lottie from "lottie-react";
import anim1 from "../../assets/anim1.json";
import { Box } from "@mui/joy";

export default function Dashboard() {
    return(
        <>
            <Box sx={{ background: 'linear-gradient(to right,#454f54, #313c42)', padding: 4, display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ background: 'transparent', maxWidth: 200 }}>
                    <img src="../logo.png" srcSet="../logo_500.png" height={200} width={200} loading="lazy" alt=""/>
                </Box>
                <Box sx={{ maxWidth: 300 }}>
                    <Lottie animationData={anim1} loop={true} />
                </Box>
            </Box>
        </>
    )
}