import React from 'react';
import './App.css';
import Dashboard from './features/dashboard/dashboard';

function App() {
  return (
    <Dashboard />
  );
}

export default App;
